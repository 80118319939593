import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { SelectPicker, Loader } from 'rsuite';
import debounce from 'lodash/debounce';

const FixedLoader = () => (
  <Loader
    content="Loading..."
    style={{
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      bottom: '0',
      background: '#fff',
      width: '100%',
      padding: '6px 0',
    }}
  />
);

const IngredientSelect = React.memo(function IngredientSelect({
  formulaIngredients = [],
  thisNameType = '',
  rowData = {},
  column = '',
  onUpdate,
  is_duplicate,
}) {
  const [data, setData] = useState(formulaIngredients);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [value, setValue] = useState('');

  useEffect(() => {
    setLoading(false);
    setData(formulaIngredients);
    setValue(rowData.ingredient_id || '');
  }, [formulaIngredients, rowData.ingredient_id]);

  // Memoize customSort to avoid recalculating on every render
  const customSort = useCallback(
    (search = '') => {
      if (!formulaIngredients.length) return [];

      const searchLower = search.toLowerCase();
      const sortedUsers = [...formulaIngredients].sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

      if (search) {
        const startsWithSearch = sortedUsers.filter(user =>
          user.label.toLowerCase().startsWith(searchLower)
        );
        const otherItems = sortedUsers.filter(
          user => !user.label.toLowerCase().startsWith(searchLower)
        );
        return [...startsWithSearch, ...otherItems];
      }

      return sortedUsers;
    },
    [formulaIngredients]
  );

  // Debounce the search input to prevent excessive renders
  const handleSearch = useMemo(() => debounce((search) => {
    setSearchText(search);
    setData(customSort(search));
  }, 300), [customSort]);

  const defaultValue = useMemo(() => {
    return formulaIngredients?.find(option => option.value === rowData.ingredient_id) || {};
  }, [formulaIngredients, rowData.ingredient_id]);

  const name = defaultValue?.display_name || '';

  return (
    <>
      <SelectPicker
        label={is_duplicate ? 'Duplicate' : ''}
        size="xl"
        placement="auto"
        placeholder={rowData.ingredient_id ? name : 'Choose...'}
        data={data}
        onSearch={handleSearch}
        onChange={(value) => {
          onUpdate(value, rowData.id, column);
        }}
        block
        value={String(rowData.ingredient_id || '')}
        renderValue={(value, item) => item?.display_name || ''}
        style={{ width: '100%' }}
      />
      {loading && <FixedLoader />}
    </>
  );
});

export default IngredientSelect;
