import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Placeholder, SelectPicker } from 'rsuite'
import { format } from 'date-fns'
import Swal from 'sweetalert2'

import './style.css'

import InputForm from '../../../helpers/InputForm'
import { OptionYesNO } from '../../../helpers/common'
//import {OptionYesNO, strEncode, JSONViewErrorMessage, ErrorMessage,formatDate} from '../../../helpers/common'
import PatientAddBtn from '../helpers/PatientAddBtn'
import { FormulaTypeConfirmBox } from '../helpers/FormulaTypeConfirmBox'

const JSONView = ({ formValue, formError }) => (
  <div className='row' style={{ marginBottom: 10 }}>
    <div class='col-4 json-tree-wrapper rs-panel'>
      <div aria-expanded='false' class='rs-panel-header' tabindex='-1'>
        <p class='rs-panel-title'>formValue</p>
      </div>
      <div role='region' class='rs-panel-body'>
        <pre>{JSON.stringify(formValue, null, 2)}</pre>
      </div>
    </div>
    <div class='col-8 json-tree-wrapper rs-panel'>
      <div aria-expanded='false' class='rs-panel-header' tabindex='-1'>
        <p class='rs-panel-title'>formError</p>
      </div>
      <div role='region' class='rs-panel-body'>
        <pre>{JSON.stringify(formError, null, 2)}</pre>
      </div>
    </div>
  </div>
)

const ErrorMessage = ({ children }) => (
  <>
    <Form.HelpText style={{ color: 'red' }}>{children}</Form.HelpText>
    {/* <span style={{ color: 'red' }}>{children}</span>*/}
  </>
)

const formatDate = (pattern) => {
  return format(new Date(), pattern)
}

export default function FormulaBuilderGeneralSection({
  formulaBuilderId,
  data,
  onGeneralSectionChange,
  numberDays,
  onNumberDaysChange,
  pracDetails,
  formulaList,
  dosageSettings,
  formulaDeatils,
  DosageMode,
  display_name,
  ApplyFormValidation,
  thisFormData,
  dosages_converting,
  formula_converting,
  is_order,
}) {
  const finalData = JSON.parse(data.general_section)
  const allow_converting = data.converting
  //console.log('allow_converting',data.converting)
  const prac_formula_setting =
    pracDetails && pracDetails.formula_setting
      ? JSON.parse(pracDetails.formula_setting)
      : []
  //console.log('pracDetails', pracDetails)
  let date_format = pracDetails.date_format ? pracDetails.date_format : 'y/M/d'
  date_format = date_format.replace('d', 'dd')
  date_format = date_format.replace('m', 'MM')
  date_format = date_format.replace('y', 'yy')
  date_format = date_format.replace('Y', 'yyyy')

  const current_formual_prac_setting =
    prac_formula_setting && prac_formula_setting[data.name]
      ? prac_formula_setting[data.name]
      : []

  const dosageModeArr = data.dosage_section
    ? JSON.parse(data.dosage_section)
    : []

  const [dosageModes, setDosageModes] = useState([])
  const [supervisorList, setSupervisorList] = useState([])

  const [formulaBuilderType, setformulaBuilderType] = useState(formulaBuilderId)
  const [formulaBuilderName, setformulaBuilderName] = useState(null)
  const [formulaBuilderDecoction, setformulaBuilderDecoction] = useState(null)
  const [formulaBuilderPatient, setformulaBuilderPatient] = useState(null)
  const [formulaBuilderDosageMode, setformulaBuilderDosageMode] = useState(null)

  const [formulaBuilderShareFormula, setformulaBuilderShareFormula] =
    useState(null)
  const [formulaBuilderPractice, setformulaBuilderPractice] = useState(null)
  const [formulaBuilderSupervisor, setformulaBuilderSupervisor] = useState(null)
  const [formulaBuilderTemplate, setformulaBuilderTemplate] = useState(null)

  const [isStudent, setIsStudent] = useState(null)
  const [dosageConverting, setDosageConverting] = useState(false)
  const [formulaConverting, setFormulaConverting] = useState(false)
  const [formulaConvertingTo, setFormulaConvertingTo] = useState(null)

  const [popup, setPopup] = React.useState(false)

  const navigate = useNavigate()

  var gernalSectionData = { ...finalData }
  var converting = { ...converting }

  const [formError, setFormError] = React.useState({})
  const [formValue, setFormValue] = React.useState({})
  var requirements = [
    {
      column: 'ingredient_selection_type',
      name: 'ingredient_selection_type',
      is_required: 0,
      key: 'ingredient_selection_type',
      allow_zero: false,
    },
  ]
  Object.entries(gernalSectionData).forEach(([key, value]) => {
    if (value.is_active == 1) {
      var allow_zero = false

      if (
        ['FORMULA_SECTION_DECOCTION', 'FORMULA_SECTION_PRACTICE'].some((item) =>
          value.key_value.includes(item)
        )
      ) {
        allow_zero = true
      }

      //&& value.is_required == 1
      requirements[value.key_value] = {
        column: value.name,
        name: value.display_name,
        is_required: value.is_required, //1,
        key: value.key_value,
        allow_zero: allow_zero,
      }
    }
  })
  // Update data according to Prac Formula Setting

  useEffect(() => {
    const {
      dosages_modes,
      default_formula_name, // custom_name,blank_name,today_date
      default_formula_name_value,
      formula_privacy_value,
      decoction_default,
      default_dosages_mode,
    } = current_formual_prac_setting || {}

    var prac_user_type =
      pracDetails && pracDetails.prac_user_type
        ? pracDetails.prac_user_type
        : 'practitioner'

    const text = dosages_modes || ''
    const textArray = text.split(',')

    /*const newData = textArray
      .map((item) => dosageModeArr?.[item])
      .filter((temp) => temp?.is_active == 1)*/
    var newData = []
    if (dosageModeArr && dosageModeArr.length != 0) {
      Object.values(dosageModeArr).map((item) => {
        var key = item.key_value
        if (textArray.includes(key) && item.is_active == 1) {
          newData.push(item)
        }
      })
    }
    //console.log('dosageModeArr',dosageModeArr,newData,textArray)
    setDosageModes(newData.length > 0 ? newData : dosageModeArr)

    var name = ''
    var privacy_value = 'shared'
    var decoction = '0'
    var selected_dosage_type = ''
    var selected_formula_type = ''
    var patient = ''
    var supervisor_list = []
    var selected_supervisor = ''
    var student = false
    var template = ''
    var practice = ''
    // Update Prac Setting
    if (formulaBuilderName == null || formulaBuilderName === '') {
      const type = default_formula_name || 'blank_name'

      if (type === 'today_date') {
        // const { year, month, day } = {
        //   year: new Date().getFullYear(),
        //   month: new Date().getMonth() + 1,
        //   day: new Date().getDate(),
        // }
        // name = `${year}-${month}-${day}`
        name = formatDate(date_format) // date_format
      } else if (type === 'custom_name') {
        name = default_formula_name_value || ''
      } else {
      }
    }
    if (current_formual_prac_setting && formulaBuilderShareFormula == null) {
      // if (formula_privacy_value == 0) {
      //   formula_privacy_value = 'private'
      // } else if (formula_privacy_value == 1) {
      //   formula_privacy_value = 'shared'
      // }
      privacy_value =
        formula_privacy_value != '' &&
        formula_privacy_value != null &&
        formula_privacy_value != 0
          ? formula_privacy_value
          : privacy_value
    }
    if (current_formual_prac_setting && formulaBuilderDecoction == null) {
      decoction = decoction_default ? decoction_default : 0
    }
    if (current_formual_prac_setting && isStudent == null) {
      student = prac_user_type == 'student' ? true : false
      if (student == true) {
        supervisor_list =
          pracDetails && pracDetails.supervisor
            ? JSON.parse(pracDetails.supervisor)
            : []
      }
    }
    if (current_formual_prac_setting && formulaBuilderDosageMode == null) {
      var thisValKey =
        default_dosages_mode != ''
          ? default_dosages_mode
          : formulaBuilderDosageMode

      if (thisValKey != 0 && thisValKey != undefined) {
        selected_dosage_type =
          dosageModeArr &&
          dosageModeArr[thisValKey] &&
          dosageModeArr[thisValKey]['is_active'] == 1
            ? dosageModeArr[thisValKey].id
            : ''
        if (selected_dosage_type == '') {
          Object.values(dosageModes).map((i, k) => {
            if (k == 0) {
              selected_dosage_type = i.id
            }
          })
        } else {
        }
      } else {
        Object.values(dosageModes).map((i, k) => {
          if (k == 0) {
            selected_dosage_type = i.id
          }
        })
      }
    }

    // Check if formulaDeatils is available before accessing its properties
    if (formulaDeatils && formulaDeatils.id) {
      name =
        formulaDeatils.name && formulaDeatils.name != ''
          ? formulaDeatils.name
          : name

      decoction = formulaDeatils.is_decocation
        ? formulaDeatils.is_decocation
        : decoction

      patient =
        formulaDeatils.patient_id &&
        formulaDeatils.patient_id != '' &&
        formulaDeatils.patient_id != 0
          ? formulaDeatils.patient_id
          : formulaBuilderPatient

      selected_dosage_type =
        formulaDeatils.formula_t != ''
          ? formulaDeatils.formula_t
          : selected_dosage_type

      privacy_value =
        formulaDeatils.formula_state != '' &&
        formulaDeatils.formula_state != null &&
        formulaDeatils.formula_state != 0
          ? //&&formulaDeatils.formula_state.toLowerCase() != 'shared' &&
            //formulaDeatils.formula_state.toLowerCase() != 'private'
            formulaDeatils.formula_state
          : privacy_value

      selected_supervisor =
        formulaDeatils.supervisor && formulaDeatils.patient_id != ''
          ? formulaDeatils.supervisor
          : selected_supervisor

      template =
        formulaDeatils.template && formulaDeatils.template != ''
          ? formulaDeatils.template
          : formulaBuilderTemplate

      practice =
        formulaDeatils.practice && formulaDeatils.practice != ''
          ? formulaDeatils.practice
          : formulaBuilderPractice
    }
    // update states
    setTimeout(() => {
      setformulaBuilderName(name)
      setformulaBuilderShareFormula(privacy_value)
      setformulaBuilderDecoction(decoction)
      setSupervisorList(supervisor_list)
      setformulaBuilderSupervisor(selected_supervisor)
      setIsStudent(student)
      setformulaBuilderDosageMode(selected_dosage_type)
      setformulaBuilderPatient(patient)
      setformulaBuilderPractice(practice)
      setformulaBuilderTemplate(template)
      //console.log('patient', patient, formulaDeatils)
    }, 200)
  }, [formulaBuilderId, formulaDeatils])

  useEffect(() => {
    onGeneralSectionChange(
      formulaBuilderType,
      formulaBuilderName,
      formulaBuilderDecoction,
      formulaBuilderPatient,
      formulaBuilderDosageMode,
      formulaBuilderShareFormula,
      formulaBuilderPractice,
      formulaBuilderSupervisor,
      formulaBuilderTemplate,
      formulaConverting,
      formulaConvertingTo
    )

    formValidation()
  }, [
    formulaBuilderType,
    formulaBuilderName,
    formulaBuilderDecoction,
    formulaBuilderPatient,
    formulaBuilderDosageMode,
    formulaBuilderShareFormula,
    formulaBuilderPractice,
    formulaBuilderSupervisor,
    formulaBuilderTemplate,
    formulaConverting,
    formulaConvertingTo,
  ])

  useEffect(() => {
    if (ApplyFormValidation == true) {
      //formValidation()
    }
  }, [ApplyFormValidation])

  const formValidation = () => {
    formValue['FORMULA_SECTION_DOSAGE'] = formulaBuilderDosageMode
    formValue['FORMULA_SECTION_SHARE_FORMULA'] = formulaBuilderShareFormula
    formValue['FORMULA_SECTION_DECOCTION'] = formulaBuilderDecoction
    formValue['FORMULA_SECTION_PRACTICE'] = formulaBuilderPractice
    formValue['FORMULA_SECTION_SUPERVISOR'] = formulaBuilderSupervisor
    formValue['FORMULA_SECTION_TEMPLATE'] = formulaBuilderTemplate
    formValue['FORMULA_SECTION_FORMULA_NAME'] = formulaBuilderName
    formValue['FORMULA_SECTION_PATIENT_NAME'] = formulaBuilderPatient
    formValue['FORMULA_SECTION_HERB_TYPE'] = formulaBuilderType
    formValue['ingredient_selection_type'] = data?.ingredient_selection_type

    var Final_array = []
    Object.values(requirements).map((i, key) => {
      if (i.is_required == 1 && i.key != 'FORMULA_SECTION_ADD_PATIENT_BUTTON') {
        if (i.allow_zero == true) {
          if (
            String(formValue[i.key]).trim() == '' ||
            formValue[i.key] == null ||
            formValue[i.key] == undefined
          ) {
            formError[i.key] = 'This field is required'
          } else {
            formError[i.key] = ''
          }
        } else {
          if (
            String(formValue[i.key]).trim() == '' ||
            formValue[i.key] == null ||
            formValue[i.key] == 0 ||
            formValue[i.key] == undefined
          ) {
            formError[i.key] = 'This field is required'
          } else {
            formError[i.key] = ''
          }
        }
      }

      var temp = {
        ...i,
        error: formError[i.key],
        value: formValue[i.key],
      }
      Final_array.push(temp)
    })
    console.log('FORMULAS_GENERAL_DETAILS',Final_array)
    thisFormData(Final_array, 'FORMULAS_GENERAL_DETAILS')
  }

  const handleGeneralSectionChange = async (
    value,
    field,
    column,
    column_name = 'formula type'
  ) => {
    console.log('handleGeneralSectionChange', value, field, column)
    if (column == 'FORMULA_SECTION_HERB_TYPE' && formulaBuilderId != value) {
      var allowthisFormulaConveting =
        allow_converting && allow_converting[value]
          ? allow_converting[value]
          : 0
      console.log(
        'allowthisFormulaConveting',
        allowthisFormulaConveting,
        allow_converting[value]
      )
      //return false
      if (allowthisFormulaConveting == 1) {
        //var allow = true//await FormulaTypeConfirmBox()
        //var allow = await window.confirm('Do you want to proceed with this action?');
        let timerInterval
        const allow = await Swal.fire({
          title: 'Are you sure?',
          text: 'you want to change ' + column_name + ' ? ',
          icon: 'warning',
          allowOutsideClick: false,
          timer: 10000,
          timerProgressBar: true,
          // didOpen: () => {
          //   //Swal.showLoading();
          //   const timer = Swal.getPopup().querySelector('#timer');
          //   if (timer) {
          //     console.log('timer', timer);
          //     timerInterval = setInterval(() => {
          //       timer.textContent = `${Math.ceil(Swal.getTimerLeft() / 1000)}s`;
          //     }, 1000);
          //   }
          // },
          // willClose: () => {
          //   //clearInterval(timerInterval);
          // },
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, change it! ',
          cancelButtonText: 'Cancel',
        })

        if (allow.isConfirmed) {
          setFormulaConverting(true)
          // setTimeout(() => {
          //   setFormulaConverting(false)
          // }, 1000)
          setFormulaConvertingTo(value)
          value = formulaBuilderId
        } else {
          value = formulaBuilderId
        }
      } else {
        alert(column_name + ' could not convert to selected formula type')
        value = formulaBuilderId
        setFormulaConvertingTo(null)
      }
    }
    const func = eval(field)
    if (func) {
      func(value)
    }
    if (column == 'FORMULA_SECTION_DOSAGE') {
      setDosageConverting(true)
      setTimeout(() => {
        setDosageConverting(false)
      }, 1000)
    }
    
    formValidation()
  }
  // Converting
  useEffect(() => {
    dosages_converting(dosageConverting)
  }, [dosageConverting])
  useEffect(() => {
    formula_converting(formulaConverting)
  }, [formulaConverting])

  if (finalData.length == 0) {
    return <Placeholder.Paragraph rows={6} />
  }

  return (
    <div className='FormulaBuilderGeneralSection'>
      <h2 className='title mt-5'>{display_name}</h2>
      {/* <pre>{JSON.stringify(Object.values(requirements), null, 2)}</pre> */}
      <div className='row' style={{ marginBottom: '1rem' }}>
        <div className='col-6'>
          {Object.entries(gernalSectionData).map(([key, value]) => {
            var thisField = []
            thisField = value
            if (value.field_type === 'select') {
              if (value.key_value == 'FORMULA_SECTION_HERB_TYPE') {
                thisField.options = formulaList //JSON.parse(data.all_builder_list)
                thisField.optionsValueField = 'id'
                thisField.optionsLabelField = 'display_name'
                thisField.hideEmptyOption = true
                thisField.triggerName = 'setformulaBuilderType'
                thisField.selectedValue = formulaBuilderType
              } else if (value.key_value == 'FORMULA_SECTION_DECOCTION') {
                thisField.options = OptionYesNO()
                thisField.optionsValueField = 'value'
                thisField.optionsLabelField = 'label'
                thisField.hideEmptyOption = true
                thisField.triggerName = 'setformulaBuilderDecoction'
                thisField.selectedValue = formulaBuilderDecoction
              } else if (value.key_value == 'FORMULA_SECTION_PATIENT_NAME') {
                var options = JSON.parse(data.all_patient_list)
                const patientOptions = options.map((item) => ({
                  firstname: item.firstname + ' ' + item.lastname,
                  id: item.id,
                }))
                thisField.options = patientOptions //JSON.parse(data.all_patient_list)
                //thisField.options = JSON.parse(data.all_patient_list)
                thisField.optionsValueField = 'id'
                thisField.optionsLabelField = 'firstname'
                thisField.hideEmptyOption = true
                thisField.triggerName = 'setformulaBuilderPatient'
                thisField.selectedValue = formulaBuilderPatient
              } else if (value.key_value == 'FORMULA_SECTION_DOSAGE') {
                const ThisOptions = dosageModes
                  ? Object.values(dosageModes).filter((v) => v.is_active == 1)
                  : []
                thisField.options = ThisOptions //JSON.parse(data.dosage_section)
                thisField.optionsValueField = 'id'
                thisField.optionsLabelField = 'display_name'
                thisField.hideEmptyOption = true
                thisField.triggerName = 'setformulaBuilderDosageMode'
                thisField.selectedValue = formulaBuilderDosageMode
                if (
                  formulaBuilderDosageMode == null ||
                  formulaBuilderDosageMode == 0
                ) {
                  Object.values(ThisOptions).map((i, k) => {
                    if (k == 0) {
                      setformulaBuilderDosageMode(i.id)
                      thisField.selectedValue = i.id
                    }
                  })
                }
              } else if (value.key_value == 'FORMULA_SECTION_SHARE_FORMULA') {
                thisField.options = [
                  { value: 'shared', label: 'Yes' },
                  { value: 'private', label: 'No' },
                ] //OptionYesNO()
                thisField.optionsValueField = 'value'
                thisField.optionsLabelField = 'label'
                thisField.hideEmptyOption = true
                thisField.triggerName = 'setformulaBuilderShareFormula'
                thisField.selectedValue = formulaBuilderShareFormula
              } else if (value.key_value == 'FORMULA_SECTION_PRACTICE') {
                thisField.options = OptionYesNO()
                thisField.optionsValueField = 'value'
                thisField.optionsLabelField = 'label'
                thisField.hideEmptyOption = true
                thisField.triggerName = 'setformulaBuilderPractice'
                thisField.selectedValue = formulaBuilderPractice
              } else if (value.key_value == 'FORMULA_SECTION_SUPERVISOR') {
                thisField.options = supervisorList
                thisField.optionsValueField = 'id'
                thisField.optionsLabelField = 'name'
                thisField.hideEmptyOption = true
                thisField.triggerName = 'setformulaBuilderSupervisor'
                thisField.selectedValue = formulaBuilderSupervisor
                thisField.is_active = value.is_active //isStudent //? isStudent :
                thisField.prac_user_type = pracDetails.prac_user_type
                if (pracDetails.prac_user_type != 'student') {
                  thisField.is_active = false
                }
              } else if (value.key_value == 'FORMULA_SECTION_TEMPLATE') {
                thisField.options = []
                thisField.optionsValueField = 'id'
                thisField.optionsLabelField = 'display_name'
                thisField.hideEmptyOption = false
                thisField.triggerName = 'setformulaBuilderTemplate'
                thisField.selectedValue = formulaBuilderTemplate
              }
              var patientBtn = []
              var searchable =
                value.key_value == 'FORMULA_SECTION_PATIENT_NAME' ? true : false
              var cleanable =
                value.key_value == 'FORMULA_SECTION_PATIENT_NAME' ? true : false
              var thisRowStyle =
                value.key_value == 'FORMULA_SECTION_PATIENT_NAME'
                  ? 'display:inline-flex;'
                  : ''
              if (value.key_value === 'FORMULA_SECTION_PATIENT_NAME') {
                var patientBtn = Object.entries(gernalSectionData).find(
                  ([key, value1]) =>
                    value1.key_value == 'FORMULA_SECTION_ADD_PATIENT_BUTTON'
                )
              }

              var optionValue = value.optionsValueField
              var optionLabel = value.optionsLabelField
              // Initialize optionData as an empty array
              var optionData = []

              // Populate optionData array
              optionData = Object.values(thisField.options)
                ? Object.values(thisField.options).map((item) => ({
                    label: item[optionLabel],
                    value: String(item[optionValue]), //String(item[optionValue]),
                  }))
                : []

              const defaultValue =
                Object.values(thisField.options)?.find(function (option) {
                  return option.value == thisField.selectedValue
                }) || []

              const name =
                defaultValue.length != 0 ? defaultValue.display_name : ''
              // sort patient list data
              if (thisField.key_value === 'FORMULA_SECTION_PATIENT_NAME') {
                optionData.sort((a, b) => {
                  // Assuming label values are strings
                  return a.label.localeCompare(b.label)
                })
              }

              if (thisField.is_active != 1) {
                return ''
              }
              var width =
                thisField.key_value === 'FORMULA_SECTION_PATIENT_NAME'
                  ? '70%'
                  : '100%'
              var error = formError[thisField.key_value]
              const is_required = thisField.is_required == 1 ? 'required' : ''
              return (
                <>
                  <div className='row mb-3 '>
                    <label for='' className='col-sm-3 col-form-label'>
                      {thisField.display_name}{' '}
                      {thisField.is_required == 1 && (
                        <span className='required'>*</span>
                      )}
                    </label>
                    <div
                      className='col-sm-9'
                      style={{ display: 'inline-flex' }}
                    >
                      <Form.Group
                        className={error ? 'has-error' : ''}
                        style={{ width: width }}
                      >
                        <SelectPicker
                          data={optionData}
                          //style={ballStyle}
                          placeholder={
                            thisField.field_placeholder != ''
                              ? thisField.field_placeholder
                              : 'Choose '
                          }
                          defaultValue={String(thisField.selectedValue)}
                          searchable={searchable}
                          onChange={(e) => {
                            if (e == null) {
                              var e = ''
                            }
                            handleGeneralSectionChange(
                              e,
                              thisField.triggerName,
                              thisField.key_value,
                              thisField.display_name
                            )
                          }}
                          name={thisField.key_value}
                          cleanable={cleanable}
                          labelKey={'label'}
                          valueKey={'value'}
                          value={String(thisField.selectedValue)}
                          errorMessage={formError[thisField.key_value]}
                          style={{ width: '100%' }}
                          required={is_required}
                          readOnly={
                            is_order == 'true' &&
                            thisField.key_value == 'FORMULA_SECTION_HERB_TYPE'
                              ? true
                              : false
                          }
                        />

                        {formError[thisField.key_value] &&
                        ApplyFormValidation ? (
                          <>
                            <ErrorMessage>
                              {formError[thisField.key_value]}
                            </ErrorMessage>
                          </>
                        ) : null}
                      </Form.Group>
                      {thisField.key_value === 'FORMULA_SECTION_PATIENT_NAME'
                        ? patientBtn &&
                          patientBtn.length !== 0 && (
                            <PatientAddBtn value={patientBtn} />
                          )
                        : ''}
                    </div>
                  </div>
                </>
              )
            } else if (
              value.field_type === 'text' &&
              value.key_value == 'FORMULA_SECTION_FORMULA_NAME'
            ) {
              value.triggerName = 'setformulaBuilderName'
              value.selectedValue = formulaBuilderName
              var error =
                ApplyFormValidation && formError[thisField.key_value]
                  ? formError[thisField.key_value]
                  : ''
              return (
                <>
                  <InputForm
                    value={value}
                    //onChange={handleGeneralSectionChange}
                    onChange={(e) => {
                      handleGeneralSectionChange(
                        e,
                        thisField.triggerName,
                        thisField.key_value
                      )
                    }}
                    name={thisField.key_value}
                    error={error}
                  />
                </>
              )
            }
          })}
        </div>

        <div className='col-6'>
          {/* <JSONView formValue={formValue} formError={formError} /> */}
        </div>
      </div>
    </div>
  )
}
