import { RadioButtonCheckedOutlined } from '@mui/icons-material'
import React, { useState, useEffect } from 'react'
import {
  SelectPicker,
  Divider,
  Table,
  Toggle,
  TagPicker,
  Button,
  Pagination,
} from 'rsuite'

import PlusIcon from '@rsuite/icons/Plus'
import { getSearchIngredients } from '../../../services/api/api_formula_builder'
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';

const { Column, HeaderCell, Cell } = Table

const IngredientSearch = ({
  ingredients,
  nameTypes,
  filter,
  addIngredient,
  selectedIngredients,
  formula_builder_id,
  ingredient_selection_type,
  allowed_ingredients,
  pracId,
  updateAddedIngredients,
  tableColumns,
  formulaData,
  setFormulaIngredientsSearch,
  formulaIngredientsSearch,
}) => {
  // console.log(formulaIngredientsSearch,"formulaIngredientsSearch123")

  const ingredientIds = selectedIngredients
    .map((item) => item.ingredient_id)
    .filter(
      (ingredientId) =>
        ingredientId !== undefined &&
        ingredientId !== null &&
        ingredientId != ''
    )
  const [AllowFilters, setAllowFilters] = useState([])

  const [addedIngredients, setAddedIngredients] = useState(ingredientIds)
  // Filters data
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedCategory, setSelectedCategory] = useState([])
  const [selectedSymptoms, setSelectedSymptoms] = useState([])
  const [selectedConditions, setSelectedConditions] = useState([])
  const [selectedActions, setSelectedActions] = useState([])
  const [selectedFlavor, setSelectedFlavor] = useState([])
  const [selectedChannel, setSelectedChannel] = useState([])
  const [selectedAttributes, setSelectedAttributes] = useState([])

  const [limit, setLimit] = React.useState(10)
  const [page, setPage] = React.useState(1)
  const [loading, setLoading] = React.useState(false)

  const [compact, setCompact] = React.useState(true)
  const [bordered, setBordered] = React.useState(true)

  const [defaultColumns, setDefaultColumns] = useState([])
  const categoryArr =
    filter && filter['category'] ? JSON.parse(filter['category']) : []

  const category = categoryArr.map((item) => ({
    label: item.name,
    value: item.id,
    category_type:
      item.category_type == 'herbs' ? 'Herb Categories' : 'Formula Categories',
  }))

  const mainColumns = [
    {
      key: 'default_herb_type',
      label: 'Default Name',
      fixed: '',
      resizable: '',
      width: 120,
    },
    {
      key: 'process_type',
      label: 'Process Type',
      fixed: '',
      resizable: '',
      width: 120,
    },
    {
      key: 'ing_name',
      label: 'Ingredient Type',
      fixed: '',
      resizable: '',
      width: 120,
    },
    // {
    //   key: 'part_of_plant',
    //   label: 'Part of Plant',
    //   fixed: '',
    //   resizable: '',
    //   width: 120,
    // },
    {
      key: 'all_cat',
      label: 'Category',
      fixed: '',
      resizable: '',
      width: 120,
    },

    {
      key: 'action',
      label: 'Action',
      fixed: 'right',
      resizable: '',
      width: 160,
    },
  ]
  const [AllowedColumns, setAllowedColumns] = useState([])
  // Ensure search_filters is parsed correctly and is an array
  const search_filters_arr = formulaData && formulaData.search_filters
    ? JSON.parse(formulaData.search_filters)
    : [];

  // Check if search_filters is an array before filtering and sorting
  const search_filters = search_filters_arr
    ? Object.values(search_filters_arr)
      .filter((filter) => filter.is_active == 1) // Filtering active items
      .sort((a, b) => a.position - b.position)    // Sorting by position
    : [];



  const [TempDataArr, setTempDataArr] = useState(formulaIngredientsSearch || [])
  //const [data, setData] = useState([])

  useEffect(() => {
    const ingredientIds = selectedIngredients
      .map((item) => item.ingredient_id)
      .filter(
        (ingredientId) =>
          ingredientId !== undefined &&
          ingredientId !== null &&
          ingredientId != ''
      )
    // For each selected object, create a new column and add it to defaultColumns
    // const newColumns = nameTypes.map((name) => ({
    //   key: name.toLowerCase().replace(' ', '_'),
    //   //label: name.replace(/_/g, ' '),
    //   label: name
    //     .replace(/_/g, ' ')
    //     .replace(/\b\w/g, (char) => char.toUpperCase()),
    //   fixed: '',
    //   resizable: true,
    //   width: 130,
    // }))
    // commented on 26 sept 2024
    // const modifiedTableColumns = tableColumns.map((column) => ({
    //   ...column,
    //   id: column.id.replace('formula_dosage_column_', ''),
    // }))
    // const TableNameColumns = modifiedTableColumns.filter((column) =>
    //   nameTypes.includes(column.id)
    // )
    const modifiedTableColumns = tableColumns.map((column) => ({
      ...column,
      id: column.id.replace('formula_dosage_column_', ''),
    }))

    // Ensure nameTypes is an array, or default to an empty array if it's undefined or null
    const TableNameColumns = modifiedTableColumns.filter((column) =>
      (nameTypes || []).includes(column.id)
    )

    const newColumns = TableNameColumns.map((column) => {
      // Add your additional fields here
      return {
        ...column,
        label: column.name,
        resizable: true,
        key: column.id,
        fixed: '',
        // Add more fields as needed
      }
    })
    //handleAllowColumns('select_all')
    // Now `modifiedColumns` contains the columns from `newColumns` with additional fields.

    setDefaultColumns([...newColumns, ...mainColumns])
    //setTimeout(() => {
    //handleAllowColumns('select_all')
    ////////////////////
    // if(formulaIngredientsSearch.length === 0){
    //   getFormulaBuilderList()
    // }
    //}, 500)
  }, [])

  useEffect(
    () => {
      let condition =
        selectedCategory.length === 0 &&
        selectedSymptoms.length === 0 &&
        selectedConditions.length === 0 &&
        selectedActions.length === 0 &&
        selectedFlavor.length === 0 &&
        selectedChannel.length === 0 &&
        selectedAttributes.length === 0
      // console.log(
      //   selectedCategory.length === 0,
      //   selectedSymptoms.length === 0,
      //   selectedConditions.length === 0,
      //   selectedActions.length === 0,
      //   selectedFlavor.length === 0,
      //   selectedChannel.length === 0,
      //   TempDataArr.length,
      //   formulaIngredientsSearch.length > 0 && !condition
      // )
      //console.log(TempDataArr[0], selectedActions)
      if (formulaIngredientsSearch.length === 0) {
        // console.log("34566")
        getFormulaBuilderList()
      }

      if (formulaIngredientsSearch.length > 0 && !condition) {
        getFormulaBuilderList()
      } else {
        // console.log("444444")
        setTempDataArr(formulaIngredientsSearch)
      }
    },
    [
      selectedCategory,
      selectedSymptoms,
      selectedConditions,
      selectedActions,
      selectedFlavor,
      selectedChannel,
      selectedAttributes
      //page,
      //limit,
    ],
    []
  )

  useEffect(() => {
    // const ingredientIds = selectedIngredients
    //   .map((item) => item.ingredient_id)
    //   .filter(
    //     (ingredientId) =>
    //       ingredientId !== undefined &&
    //       ingredientId !== null &&
    //       ingredientId != ''
    //   )
    //setAddedIngredients(ingredientIds) instead add to formula
    updateAddedIngredients(addedIngredients)
    //alert(selectedIngredients)
  }, [addedIngredients])

  useEffect(() => {
    //setTimeout(() => {
    handleAllowColumns('select_all')
    //}, 500)
  }, [defaultColumns])

  // useEffect(() => {}, [TempDataArr])

  const [columnKeys, setColumnKeys] = React.useState([]) // with Select All option

  const columns = defaultColumns.filter((column) =>
    columnKeys.some((key) => key === column.key)
  )

  const handleChangeLimit = (dataKey) => {
    setPage(1)
    setLimit(dataKey)
  }

  const handleAddIngredient = (ingredient) => {
    //console.log('handleAddIngredient', ingredient)
    //setAddedIngredients((prevIngredients) => [...prevIngredients, ingredient])
    if (
      !addedIngredients.some((addedIngredient) => addedIngredient == ingredient)
    ) {
      setAddedIngredients((prevIngredients) => [...prevIngredients, ingredient])
      //addIngredient(ingredient) // onClick Add to formula Btn insentent add to formual
    }
  }

  const isIngredientAdded = (ingredient) => {
    // Check if the ingredient is in the list of added ingredients.
    // return addedIngredients.some(
    //   (addedIngredient) => addedIngredient == ingredient
    // )
    //return addedIngredients.includes(ingredient)
    let idExists = false
    for (let i = 0; i < addedIngredients.length; i++) {
      if (addedIngredients[i] == ingredient) {
        idExists = true
        break // Exit the loop once a match is found
      }
    }
    return idExists
  }
  // save with Select All option
  const handleAllowColumns = (values) => {
    // Check if the "Select All" option is selected
    if (values.includes('select_all')) {
      // Include all keys except the ones to be disabled
      const allColumnKeys = defaultColumns
        .filter(
          (column) =>
            ![
              'default_herb_type',
              'ing_name',
              'process_type',
              'all_cat',
              'action',
            ].includes(column.key)
        )
        .map((column) => column.key)
      //setColumnKeys(allColumnKeys)
      setColumnKeys([
        ...allColumnKeys,
        ...[
          'default_herb_type',
          'ing_name',
          'process_type',
          'all_cat',
          'action',
        ],
      ])
    } else {
      //setColumnKeys(values)
      setColumnKeys([
        ...values,
        ...[
          'default_herb_type',
          'ing_name',
          'process_type',
          'all_cat',
          'action',
        ],
      ])
    }
  }

  // Create a custom "Select All" option
  const selectAllOption = {
    key: 'select_all',
    label: 'Select All',
  }

  const getFormulaBuilderList = async () => {
    setLoading('true')
    const response = await getSearchIngredients({
      pracId,
      formula_builder_id,
      ingredient_selection_type,
      allowed_ingredients,
      selectedCategory,
      selectedSymptoms,
      selectedConditions,
      selectedActions,
      selectedFlavor,
      selectedChannel,
      selectedAttributes
    })
    //console.log('getFormulaBuilderList', response)
    const filteredData = response

    setTempDataArr(response ? response : [])
    let condition =
      selectedCategory.length === 0 &&
      selectedSymptoms.length === 0 &&
      selectedConditions.length === 0 &&
      selectedActions.length === 0 &&
      selectedFlavor.length === 0 &&
      selectedChannel.length === 0 &&
      selectedAttributes.length === 0
    if (formulaIngredientsSearch.length === 0) {
      setFormulaIngredientsSearch(response ? response : [])
    }

    setLoading(false)
    //return response
  }
  var data = []
  if (Array.isArray(TempDataArr)) {
    TempDataArr.sort((a, b) => {
      const isEmpty = (str) => str === null || str === undefined || str === ''

      const getTypeOrder = (str) => {
        if (isEmpty(str)) {
          return 4 // Blank
        } else if (/^[0-9]+$/.test(str)) {
          return 3 // Numbers
        } else if (/^[a-zA-Z]+$/.test(str)) {
          return 0 // Normal string
        } else if (/^[a-zA-Z0-9]+$/.test(str)) {
          return 1 // Normal string
        } else {
          return 2 // Special characters
        }
      }

      const typeOrderA = getTypeOrder(a.default_herb_type)
      const typeOrderB = getTypeOrder(b.default_herb_type)

      if (typeOrderA !== typeOrderB) {
        return typeOrderA - typeOrderB
      }

      // If types are the same, sort alphabetically
      return a.default_herb_type.localeCompare(b.default_herb_type)
    })

    // Assuming TempDataArr is your array
    const filteredData = TempDataArr.filter(
      (item) => item.product_type === 'herbs'
    )

    data = filteredData.filter((v, i) => {
      //data = TempDataArr.filter((v, i) => {
      const start = limit * (page - 1)
      const end = start + limit
      return i >= start && i < end
    })
    // Use paginatedData as needed
  }

  const columnArr = [...new Set(columnKeys)]

  const updateFormulaIngredientList = (
    formulaIngredients,
    extra,
    FieldsName,
    seperator
  ) => {
    if (formulaIngredients && formulaIngredients.length != 0) {
      var yesNoFields = new Set([
        'toxic',
        'external_use_only',
        'aristolic_acid',
        'contains_cholesterol',
        'gmo_free',
        'contains_mustard',
        'contains_sulphites',
        'contains_sesame',
        'contains_nuts',
        'madeInaPeanutFreeFacility',
        'caution_with_pregnancy',
        'organic',
        'kosher',
        'wildcrafted',
        'contains_gluten',
        'contains_corn',
        'contains_egg',
        'contains_animal_products',
        'contains_wheat',
        'contains_fish',
        'contains_soy',
        'contains_artificial_sweeters',
        'contains_peanuts',
        'contains_milk',
        'contains_shellfish',
        'contains_treenuts',
        'vegan',
      ])
      var IngredientOptionArr = []
      var IngredientsIdArr = []

      nameTypes.map((name) => {
        //var name = 'name'
        var key = 'formula_dosage_column_' + name
        var ingredientOptions = formulaIngredients.map((item, kk) => {
          //if (item[name]) {
          if (item[name] != '') {
            var labelValue = item[name]
            var ing_name = item[name]
            var display_name = item[name]
          } else {
            var labelValue = item['default_herb_type']
              ? item['default_herb_type']
              : null

            var ing_name = labelValue
            var display_name = labelValue
          }
          labelValue += seperator + item.ing_name
          if (extra.length != 0) {
            extra.map((i) => {
              if (i == 'ratio,ratio2') {
                if (
                  item.ratio !== undefined &&
                  item.ratio2 !== undefined &&
                  item.ratio != '' &&
                  item.ratio2 != ''
                ) {
                  labelValue += seperator + item.ratio + ':' + item.ratio2
                }
              } else if (
                item[i] != '' &&
                item[i] != null &&
                item[i] !== undefined
              ) {
                if (yesNoFields.has(i)) {
                  labelValue += seperator + FieldsName[i]
                } else {
                  labelValue += seperator + item[i]
                }
              }
            })
          }

          if (
            item['process_type'] != '' &&
            item['process_type'] != null &&
            item['process_type'] !== undefined
          ) {
            display_name += seperator + item['process_type']
          }

          IngredientsIdArr.push(item.id)
          return {
            ing_name: ing_name,
            label: labelValue,
            display_name: display_name,
            value: item.id,
            key: name + '_' + item.id + '_' + kk,
            herb_type: item.ing_key,
          }
          //}
        })
        ingredientOptions.sort((a, b) => a.label.localeCompare(b.label))
        // Filter out null and undefined elements from the array
        // Filter out null and undefined elements from the array
        const filteredArray = ingredientOptions.filter(
          (item) => item !== null && item !== undefined
        )
        var mergedItems = [
          ...IngredientOptionArr['formula_dosage_column_all_names'],
          ...filteredArray,
        ]
        mergedItems.sort((a, b) => a.label.localeCompare(b.label))
        IngredientOptionArr['formula_dosage_column_all_names'] = mergedItems
        IngredientOptionArr[key] = filteredArray
      })
      IngredientOptionArr['formula_dosage_column_all_names'] =
        IngredientOptionArr['formula_dosage_column_name']
      ///////////////////////////////////////////////////////////
      var IngredientsIdArr = Array.from(new Set(IngredientsIdArr))
      //setformulaIngredientsIdArr(IngredientsIdArr)
      //setformulaIngredients(IngredientOptionArr)
      ///////////////////////////////////////////////////////////
      // console.log('IngredientOptionArr', IngredientOptionArr, IngredientsIdArr)
    } else {
      //setformulaIngredientsIdArr([])
      //setformulaIngredients([])
    }
  }

  const filterFormulaColums = () => {
    const modifiedTableColumns = tableColumns.map((column) => ({
      ...column,
      id: column.id.replace('formula_dosage_column_', ''),
    }))
    const TableNameColumns = modifiedTableColumns.filter((column) =>
      nameTypes.includes(column.id)
    )
    return TableNameColumns
    // Now `modifiedTableColumns` contains only the columns whose id is present in the `nameTypes` array.
  }

  return (
    <div className='row FormulaIngredientSearch'>
      {/* <pre>{JSON.stringify(search_filters, null, 2)}</pre> */}
      {/* Add select inputs for filtering */}
      <div className='row'>
        {Object.values(search_filters).map((thisfilter) => (
          <>
            {thisfilter.key_value == 'SEARCH_FILTER_ACTIONS' ? (
              <div className='col-2'>
                <TagPicker
                  placeholder={thisfilter.display_name}
                  size='sm'
                  data={
                    filter && filter['actions']
                      ? JSON.parse(filter['actions']).sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )
                      : []
                  }
                  value={selectedActions}
                  onChange={setSelectedActions}
                  cleanable={false}
                  labelKey='name'
                  valueKey='id'
                  placement='auto'
                  style={{ width: '100%' }}
                />
              </div>
            ) : thisfilter.key_value ==
              'SEARCH_FILTER_ALLOPATHIC_CONDITIONS' ? (
              <div className='col-2'>
                <TagPicker
                  placeholder={thisfilter.display_name}
                  size='sm'
                  data={
                    filter && filter['allopathic_conditions']
                      ? JSON.parse(filter['allopathic_conditions']).sort(
                        (a, b) => a.name.localeCompare(b.name)
                      )
                      : []
                  }
                  value={selectedConditions}
                  onChange={setSelectedConditions}
                  cleanable={false}
                  labelKey='name'
                  valueKey='id'
                  placement='auto'
                  style={{ width: '100%' }}
                />
              </div>
            ) : thisfilter.key_value == 'SEARCH_FILTER_CATEGORY' ? (
              <div className='col-2'>
                <TagPicker
                  placeholder={thisfilter.display_name}
                  size='sm'
                  data={category.sort((a, b) => a.label.localeCompare(b.label))}
                  groupBy='category_type'
                  value={selectedCategory}
                  onChange={setSelectedCategory}
                  cleanable={false}
                  placement='auto'
                  style={{ width: '100%' }}
                />
              </div>
            ) : thisfilter.key_value == 'SEARCH_FILTER_CHANNEL' ? (
              <div className='col-2'>
                <TagPicker
                  placeholder={thisfilter.display_name}
                  size='sm'
                  data={
                    filter && filter['channel']
                      ? JSON.parse(filter['channel']).sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )
                      : []
                  }
                  value={selectedChannel}
                  onChange={setSelectedChannel}
                  cleanable={false}
                  labelKey='name'
                  valueKey='id'
                  placement='auto'
                  style={{ width: '100%' }}
                />
              </div>
            ) : thisfilter.key_value == 'SEARCH_FILTER_FLAVOR' ? (
              <div className='col-2'>
                <TagPicker
                  placeholder={thisfilter.display_name}
                  size='sm'
                  data={
                    filter && filter['flavor']
                      ? JSON.parse(filter['flavor']).sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )
                      : []
                  }
                  value={selectedActions}
                  onChange={setSelectedActions}
                  cleanable={false}
                  labelKey='name'
                  valueKey='id'
                  placement='auto'
                  style={{ width: '100%' }}
                />
              </div>
            ) : thisfilter.key_value == 'SEARCH_FILTER_SYMPTOMS' ? (
              <div className='col-2'>
                <TagPicker
                  placeholder={thisfilter.display_name}
                  size='sm'
                  data={
                    filter && filter['symptoms']
                      ? JSON.parse(filter['symptoms']).sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )
                      : []
                  }
                  value={selectedSymptoms}
                  onChange={setSelectedSymptoms}
                  cleanable={false}
                  labelKey='name'
                  valueKey='id'
                  placement='auto'
                  style={{ width: '100%' }}
                />
              </div>
            ) : thisfilter.key_value == 'SEARCH_FILTER_ATTRIBUTES' ? (
              <div className='col-2'>
                <TagPicker
                  placeholder={thisfilter.display_name}
                  size='sm'
                  data={
                    filter && filter['attributes']
                      ? JSON.parse(filter['attributes']).sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )
                      : []
                  }
                  value={selectedAttributes}
                  onChange={setSelectedAttributes}
                  cleanable={false}
                  labelKey='name'
                  valueKey='key'
                  placement='auto'
                  style={{ width: '100%' }}

                />
              </div>
            ) : (
              <></>
            )}
          </>
        ))}

        {/* <input
        className='col-12'
        type='text'
        placeholder='Search ingredients...'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      /> */}
      </div>
      <div className='col-12' style={{ marginTop: '1rem', display: 'none' }}>
        Columns：
        <TagPicker
          className='col'
          //data={defaultColumns}
          data={[selectAllOption, ...defaultColumns]}
          labelKey='label'
          valueKey='key'
          //value={columnKeys}
          value={columnArr}
          //onChange={setColumnKeys}
          onChange={handleAllowColumns}
          cleanable={false}
          style={{ minWidth: '25%' }}
          disabledItemValues={[
            'default_herb_type',
            'ing_name',
            'action',
            'process_type',
            'all_cat',
          ]}
        />
      </div>
      {/* <pre>{JSON.stringify(columns, null, 2)}</pre> */}
      {/* <Divider></Divider> */}
      <div style={{ height: true ? 'auto' : 400, marginTop: '1rem' }}>
        <Table
          loading={loading}
          //wordWrap='break-word'
          height={300}
          autoHeight={true}
          bordered={true}
          cellBordered={true}
          data={data}
          renderEmpty={() => (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <img
                width='8%'
                src='https://i.pinimg.com/originals/ed/03/a8/ed03a8a3babdf60e1f43fc2d0bc0468a.gif'
                alt='No Ingredients'
              />
              <h5>No Ingredients</h5>
              {/* You can customize this message or content */}
            </div>
          )}
        >
          {columns.map((column) => {
            if (column.key !== 'all_names') {
              const { key, label, ...rest } = column
              const width = Number((window.innerWidth - 150) / columns.length)
              return (
                <Column
                  //width={column.width}
                  width={width}
                  key={column.key}
                  flexGrow={column.flexGrow}
                  //sortable
                  fullText
                  resizable={column.resizable}
                  fixed={column.fixed}
                >
                  <HeaderCell>{column.label}</HeaderCell>
                  <Cell dataKey={column.key}>
                    {(rowData) => {
                      var dataKey = column.key
                      if (dataKey === 'action') {
                        return isIngredientAdded(rowData.id) ? (
                          <span>
                            <svg width='24' height='24' viewBox='0 0 24 24'>
                              <path
                                d='M6.293 9.293l-1.414 1.414 5.293 5.293 9-9-1.414-1.414-7.586 7.586z'
                                fill='#00A000'
                              />
                            </svg>
                            Added
                          </span>
                        ) : (
                          <>
                            {/* <button
                            row={rowData.id}
                            type='button'
                            style={{ backgroundColor: '#fff0' }}
                            onClick={() => handleAddIngredient(rowData.id)}
                            className='ingredientAddToFormula'
                          >
                            <PlusIcon></PlusIcon> Add to Formula
                          </button> */}
                            <Button
                              size='sm'
                              color='blue'
                              appearance='ghost'
                              onClick={() => handleAddIngredient(rowData.id)}
                            >
                              <PlusIcon></PlusIcon> Add to Formula
                            </Button>
                          </>
                        )
                      } else {
                        if (dataKey == 'all_names') {
                          return (
                            <span title={rowData['default_herb_type']}>
                              {rowData['default_herb_type']}
                            </span>
                          )
                        } else {
                          return (
                            <span title={rowData[dataKey]}>
                              {rowData[dataKey]}
                            </span>
                          )
                        }
                      }
                    }}
                  </Cell>
                </Column>
              )
            }
          })}
        </Table>
        {TempDataArr.length != 0 && (
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size='xs'
              layout={['total', '-', 'limit', '|', 'pager', 'skip']}
              //total={ingredients.length}
              total={TempDataArr.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default IngredientSearch
