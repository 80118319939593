import React, { useEffect } from 'react'
import { CheckPicker, Tooltip, Whisper,Popover } from 'rsuite'
import SpinnerIcon from '@rsuite/icons/legacy/Spinner'

function renderDynamicTable(data) {
  if (!Array.isArray(data) || data.length === 0) {
    return <p>No Ingredients available</p>;
  }

  const sumByUnit = (data, key) => {
    return data.reduce((acc, herb) => {
      if (!acc[herb.unit]) {
        acc[herb.unit] = 0;
      }
      acc[herb.unit] += parseFloat(herb[key]);
      return acc;
    }, {});
  };
  
  const formatTotalsByUnit = (totals) => {
    return Object.entries(totals)
      .map(([unit, total]) => `${total.toFixed(3)} ${unit}`)
      .join(' + ');
  };

  return (
    <div>
      {data && data.length == 0 ? (
        <div>Formula has no Ingredients</div>
      ) : (
        <table className='table' style={{ fontSize: '10px' }}>
          <thead>
            <tr>
              {/* <th>Herb ID</th> */}
              <th>Ingredient Name</th>
              <th className='text-center'>Ingredient Type</th>
              <th className='text-center'>Raw Quantity</th>
              <th className='text-center'>Enter Quantity</th>
              <th className='text-center'>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {data.map((herb) => (
              <tr key={herb.herb_id}>
                {/* <td>{herb.herb_id}</td> */}
                <td>{herb.herb_name}</td>
                <td className='text-center'>{herb.herb_type || ''}</td>
                <td className='text-center'>{parseFloat(herb.raw_qty).toFixed(3)} {herb.unit}</td>
                <td className='text-center'>{parseFloat(herb.enter_qty).toFixed(3)} {herb.unit}</td>
                <td className='text-center'>{parseFloat(herb.quantity).toFixed(3)} {herb.unit}</td>
              </tr>
            ))}
          </tbody>
          {data && data.length > 0 && (
            <tfoot>
              <tr style={{fontWeight:'bold'}}>
                <td colSpan="2">Total Ingredient: {data.length}</td>
                <td className='text-center'>
                  {formatTotalsByUnit(sumByUnit(data, 'raw_qty'))}
                </td>
                <td className='text-center'>
                {formatTotalsByUnit(sumByUnit(data, 'enter_qty'))}
                </td>
                <td className='text-center'>
                {formatTotalsByUnit(sumByUnit(data, 'quantity'))}
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      )}
    </div>
  );
  
}


export const TabDropdown = ({
  name = 'Formula',
  options = [],
  onChange,
  newTabData,
  onClose,
  onRemove,
}) => {
  const [items, setItems] = React.useState([])
  const [selected, setSelected] = React.useState([])
  const [removed, setRemoved] = React.useState([])
  const [selectedFormulas, setSelectedFormuls] = React.useState([])

  useEffect(() => {
    setItems(options)
  }, [options])

  const handleClose = () => {
    var allFormulaHerbs = []
    var allFormulas = []
    var selectedHerbIds = []
    selected.map((formulaId) => {
      const thisFormula = options.find((item) => item.id == formulaId)
      if (thisFormula) {
        var allHerbs = thisFormula.all_herbs
        if (allHerbs != null && allHerbs.length != 0) {
          allHerbs.map((ing) => {
            selectedHerbIds.push(ing.herb_id)
            ing['formula_id'] = formulaId
            ing['formula_name'] = thisFormula.name
            allFormulaHerbs.push(ing)
          })
        }
        allFormulas.push(thisFormula)
      }
    })
    if (allFormulas.length != 0) {
      //onClose(selected, allFormulaHerbs)
      onClose(allFormulas, allFormulaHerbs)
    }
    ///////////////
    var allRemovedFormulaHerbs = []
    var removedHerbIds = []
    removed.map((formulaId2) => {
      const thisFormula = options.find((item) => item.id == formulaId2)
      if (thisFormula && allFormulas.includes(formulaId2) == false) {
        var allHerbs = thisFormula.all_herbs
        if (allHerbs != null && allHerbs.length != 0) {
          allHerbs.map((ing) => {
            console.log('ing', ing)
            removedHerbIds.push(ing.herb_id)
            ing['formula_id'] = thisFormula.id
            ing['formula_name'] = thisFormula.name
            allRemovedFormulaHerbs.push(ing)
          })
        }
      }
    })
    if (allRemovedFormulaHerbs.length != 0) {
      //console.log('removeFormulaIngredients', selected, allRemovedFormulaHerbs)
      onRemove(selected, allRemovedFormulaHerbs)
      setRemoved([])
    }
  }

  const disableItems = []
  const datat = Array.isArray(options)
    ? options.map((item) => ({
        label: (
          <>
            {item.name}{' '}
            {item.total_herb != 0 && (
              <span title={item.total_herb + ' Ingredients'}>
                ( {item.total_herb} Ingredients )
              </span>
            )}
          </>
        ),
        value: item.id,
        name: item.name,
        all_herbs:item.all_herbs,
        count_herbs:item.total_herb
      }))
    : []
  const sortBySelected = (a, b) => {
    const isASelected = selected.includes(a.id)
    const isBSelected = selected.includes(b.id)

    if (isASelected && !isBSelected) {
      return -1
    }
    if (!isASelected && isBSelected) {
      return 1
    }
    return a.id - b.id // Maintain the original order for non-selected users
  }
  var data = [...datat].sort(sortBySelected)

  const updateData = () => {
    if (items.length === 0) {
      setItems(data)
    }
  }
  const renderMenu = (menu) => {
    if (options.length == 0) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          No {name ? name : 'Formulas'}
        </p>
      )
    }
    if (items.length === 0) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <SpinnerIcon spin /> loading...
        </p>
      )
    }
    return menu
  }
  const renderMenuItem = (label, item) => {
    console.log('renderTabMenuItem',item)
    const herb_table = renderDynamicTable(item.all_herbs);
    return (
      <Whisper placement="auto" followCursor speaker={<Popover placement="auto">{herb_table}</Popover>}>
       {label}
      </Whisper>
    );
  };

  function checkArray(oldArray, newArray) {
    // Find new values (present in newArray but not in oldArray)
    const newValues = newArray.filter((value) => !oldArray.includes(value))

    // Find removed values (present in oldArray but not in newArray)
    const removedValues = oldArray.filter((value) => !newArray.includes(value))

    return {
      newValues,
      removedValues,
    }
  }


  return (
    <>
      {/* <pre>{JSON.stringify(removed, null, 2)}</pre> */}
      <CheckPicker
        placement='auto'
        placeholder={name}
        className='col-12 ingredientTabCheckbox'
        data={data}
        //onOpen={updateData}
        //onSearch={updateData}
        renderMenu={renderMenu}
        renderMenuItem={renderMenuItem}
        value={selected}
        onChange={(e, extra) => {
          const { newValues, removedValues } = checkArray(selected, e)
          setSelected(e)
          //console.log('CheckPicker', newValues, removedValues)
          if (newValues.length !== 0) {
            newTabData(newValues)

            //onChange(e, extra, options, true)
          } else {
            //onChange(e, extra, options, false)
            setRemoved((prevRemoved) => [...prevRemoved, removedValues])
            //removed.push(removedValues)
            // let finalArr = []
            // if (removedValues.length != 0) {
            //   finalArr = removedValues.every((value) => e.includes(value))
            // }
            // console.log('removeFinalArr', finalArr, removedValues)
            // if (removedValues) {
            //   setRemoved((prevRemoved) => [...prevRemoved, ...removedValues])
            // }

            // let finalRemove = removedValues.every((value) => e.includes(value))
            // if (finalRemove != false) {
            //   let temp = [...finalArr, ...finalRemove]
            //   setRemoved(temp)
            //   console.log('removeFinalArr', temp)
            // } else {
            //   setRemoved(finalArr)
            //   console.log('removeFinalArr', finalArr)
            // }
          }
        }}
        cleanable={false}
        onClose={handleClose}
      />
    </>
  )
}
