import React, { useEffect, useState } from 'react'
import { Drawer, ButtonToolbar, Button, Placeholder } from 'rsuite'

import 'rsuite/dist/rsuite.min.css'
import PlusIcon from '@rsuite/icons/Plus'
import { APIConfig } from '../../../apiConfig'
const { PATIENT_ADD_FORM_URL } = APIConfig

function PatientAddBtn({ value }) {
  var value = value[1]
  const [popup, setPopup] = React.useState(false)

  const ballStyle = {
    backgroundColor: value.field_bg_color || '#ffb848',
    fontWeight: value.field_bold,
    fontSize: value.field_font_size,
    marginBottom: value.field_margin_bottom,
    marginLeft: value.field_margin_left,
    marginRight: value.field_margin_right,
    marginTop: value.field_margin_top,
    textDecoration: value.field_underline,
    visibility: value.is_active ? 'visible' : 'hidden',
    border: 'none',
    width:
      value.width != 0 && value.width != ''
        ? `${value.width}px`
        : 'max-content',
    float: 'right',
    color: value.field_font_color || 'white',
  }

  if (value.is_active != 1) {
    return ''
  }
  const Hidden = { display: value.is_active }
  return (
    <>
      {/* <pre>{JSON.stringify(value, null, 2)}</pre> */}
      <a
        className='btn btn-secondary'
        //style={ballStyle}
        style={{
          float: 'right',
          display: 'block',
          width: '30%',
          marginLeft: '4px',
          background: '#3B3F48',
          color: '#fff',
          height: '35px',
          textDecoration: 'none',
          padding: '3px',
        }}
        // onClick={() => {
        //   window.location.href = PATIENT_ADD_FORM_URL
        // }}
        href={PATIENT_ADD_FORM_URL}
        target='_blank'
        //onClick={() => setPopup(true)}
      >
        <PlusIcon style={{ fontSize: '12px', marginTop: '-3px' }} />{' '}
        {value.display_name}
      </a>

      {/* <Drawer open={popup} onClose={() => setPopup(false)}>
        <Drawer.Header>
          <Drawer.Title>Add Patient</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setPopup(false)}>Close</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          {PATIENT_ADD_FORM_URL && (
            <iframe
              className='Add_PATIENT_IFRAME'
              title='Patient Add Form'
              src={PATIENT_ADD_FORM_URL}
              width='auto'
              height='auto'
              frameBorder='0'
              allowFullScreen
            />
          )}
        </Drawer.Body>
      </Drawer> */}
    </>
  )
}

export default PatientAddBtn
